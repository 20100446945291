/**
 * JS获取距当前时间差
 *
 * @param int time 时间戳格式
 *
 */
export function get_time_diff(time) {
	// let diff = '';
	let hours1 = ''
	let minutes1= ''
	let seconds1= ''
	const time_diff = new Date().getTime() - time; //时间差的毫秒数

	//计算出相差天数
	// const days = Math.floor(time_diff / (24 * 3600 * 1000));
	// if (days > 0) {
	//   diff += days;
	// }
	//计算出小时数
	const leave1 = time_diff % (24 * 3600 * 1000);
	const hours = Math.floor(leave1 / (3600 * 1000));
	if (hours > 0) {
		hours1 += hours;
	} else {
		if (hours1 !== '') {
			hours1 += hours;
		}
	}
	//计算相差分钟数
	const leave2 = leave1 % (3600 * 1000);
	const minutes = Math.floor(leave2 / (60 * 1000));
	if (minutes > 0) {
		minutes1 += minutes;
	} else {
		if (minutes1 !== '') {
			minutes1 += minutes;
		}
	}
	//计算相差秒数
	const leave3 = leave2 % (60 * 1000);
	const seconds = Math.round(leave3 / 1000);
	if (seconds > 0) {
		seconds1 += seconds;
	} else {
		if (seconds1 !== '') {
			seconds1 += seconds;
		}
	}

	return {hours1:hours1,minutes1:minutes1,seconds1:seconds1};
}
