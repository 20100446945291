
<!--维修单明细-->
<template>
  <div class="app-container page-container">
    <div class="section">
      <!--页头-->
      <div class="page-header">
        <div class="ph-left">
          <!--页面标题-->
          <h2 class="page-title">维修单明细</h2>
        </div>
        <div class="ph-right">
          <el-button size="small" type="primary" icon="el-icon-back" @click="back">返回</el-button>
        </div>
      </div>
      <div class="form-box" style="max-width: 1000px">
        <el-form
            ref="infoForm"
            :model="formList"
            label-width="120px"
            label-position="left"
        >
          <div class="title">
            <div>基本信息</div>
            <div>
              <span style="color:#37C239;" v-if="formList.type === '0'">未开始</span>
              <span style="color:#EA000E;" v-if="formList.type === '1'">待开始</span>
              <span style="color:#409eff;" v-if="formList.type === '2'">进行中</span>
              <span style="color:#F7B515;" v-if="formList.type === '3'">待确认</span>
              <span style="color:#666666;" v-if="formList.type === '4'">已完成</span>
              <span style="color:#999999;" v-if="formList.type === '5'">已取消</span>
            </div>
          </div>
          <el-form-item label="报修单号" prop="name">
            <el-input
                v-model.trim="formList.code"
                :disabled="true"
                maxlength="32"
                clearable
                placeholder="请填写设备名称"
                size="medium"
            />
          </el-form-item>
          <el-form-item label="设备名称" prop="" style="max-width: 1000px">
            <el-input
                v-model="formList.deviceName"
                :disabled="true"
                clearable
                placeholder="请填写设备编码"
                size="medium"
                maxlength="20"
            />
          </el-form-item>
          <el-form-item label="设备型号" prop="code">
            <el-input
                v-model.trim="formList.device"
                :disabled="true"
                clearable
                placeholder="请填写机身编号"
                size="medium"
                maxlength="20"
            />
          </el-form-item>
          <el-form-item label="机身编号" prop="name">
            <el-input
                v-model.trim="formList.deviceCode"
                :disabled="true"
                maxlength="32"
                clearable
                placeholder="请填写设备名称"
                size="medium"
            />
          </el-form-item>
          <el-form-item label="报修时间" prop="name">
            <el-input
                v-model.trim="formList.repairTime"
                :disabled="true"
                maxlength="32"
                clearable
                placeholder="请填写设备名称"
                size="medium"
            />
          </el-form-item>
          <el-form-item label="报修人员" prop="name">
            <el-input
                v-model.trim="formList.repairPerson"
                :disabled="true"
                maxlength="32"
                clearable
                placeholder="请填写设备名称"
                size="medium"
            />
          </el-form-item>
          <el-form-item label="维修人员" prop="name">
            <el-input
                v-model.trim="formList.maintainPerson"
                :disabled="true"
                maxlength="32"
                clearable
                placeholder="请填写设备名称"
                size="medium"
            />
          </el-form-item>
          <div>
            <div class="title">详细信息</div>
            <el-form-item label="故障类型" prop="code">
              <el-input
                  v-model.trim="formList.repairType"
                  :disabled="true"
                  clearable
                  placeholder="请填写机身编号"
                  size="medium"
                  maxlength="20"
              />
            </el-form-item>
            <el-form-item label="故障描述" prop="" style="max-width: 1000px">
              <el-input
                  v-model="formList.repairInfo"
                  :disabled="true"
                  clearable
                  placeholder="请填写设备编码"
                  size="medium"
                  maxlength="20"
              />
            </el-form-item>
            <el-form-item label="故障图片" prop="code">
              <div v-if="!formList.pictureUrl">暂无图片</div>
              <div style="color: #666666;width: 450px;display:block;" class="" v-if="formList.pictureUrl">
                <div class="group">
                  <el-row :gutter="24"  >
                    <el-col :span="6" v-for="(item,index) in formList.pictureUrl.split(',')" :key="index">
                      <div class="item_img">
                        <el-image
                            style="width: 100%"
                            :src="item"
                            :preview-src-list="formList.pictureUrl.split(',')">
                        </el-image>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="故障视频" prop="code">
              <span class="bofang" @click="playVideo">点击播放</span>
            </el-form-item>
          </div>
          <div >

            <div v-for="(item, index) in infoList" :key="index">
              <div v-if="infoList.length > 0" class="title">维修信息</div>
              <el-form-item label="维修人员" prop="code">
                <el-input
                    v-model.trim="item.username"
                    :disabled="true"
                    clearable
                    placeholder="请填写机身编号"
                    size="medium"
                    maxlength="20"
                />
              </el-form-item>
              <el-form-item label="维修时间" prop="code">
                <el-input
                    v-model.trim="item.date"
                    :disabled="true"
                    clearable
                    placeholder="请填写机身编号"
                    size="medium"
                    maxlength="20"
                />
              </el-form-item>
              <el-form-item label="故障描述" prop="code">
                <el-input
                    v-model.trim="item.summary"
                    :disabled="true"
                    clearable
                    placeholder="请填写机身编号"
                    size="medium"
                    maxlength="20"
                />
              </el-form-item>
              <el-form-item label="故障图片" prop="code">
                <div v-if="!item.pictureUrls">暂无图片</div>
                <div style="color: #666666;width: 450px;display:block;" class="" v-if="item.pictureUrls">
                  <div class="group">
                    <el-row :gutter="20">
                      <el-col :span="6" v-for="(line,lineIndex) in item.pictureUrls.split(',')" :key="lineIndex">
                        <div class="item_img">
                          <el-image
                              style="width: 100%"
                              :src="line"
                              :preview-src-list="item.pictureUrls.split(',')">
                          </el-image>
                          <!--                  <img :src="item" style="width: 100%;" @click="openImg(index)"/>-->
                        </div>

                      </el-col>

                    </el-row>
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="故障视频" prop="code">
                <span class="bofang" @click="playVideo2(item)">点击播放</span>
              </el-form-item>

            </div>
            <el-form-item v-if="formList.type === '2' && role !== 2" prop="code">
              <div style="text-align: center;color:#F7B515;font-size:28px">
                <span v-if="userId === formList.maintainPersonId">{{time}}</span>
              </div>
            </el-form-item>
            <el-form-item>
              <div v-if="role === 2">
                <el-button class="button-work" type="primary" @click="handleSend" v-if="formList.type === '0'">
                  指派
                </el-button>
              </div>
              <div v-if="formList.type === '1' && role !== 2">
                <el-button class="button-work" type="primary" @click="handleStart" v-if="userId === formList.maintainPersonId">
                  开始工作
                </el-button>
              </div>
              <div v-if="formList.type === '1'">
                <el-button type="primary" class="button-work" @click="handleSend" v-if="role === 2">
                  改派
                </el-button>
              </div>
              <div v-if="formList.type === '2' && role !== 2">
                <el-button type="primary" class="button-work" v-if="userId === formList.maintainPersonId"  @click="handleEnd">
                  工作结束，填写报告
                </el-button>
              </div>

              <div class="bottom-button" v-if="formList.type === '3'">
                <div @click="handleSend" v-if="role === 2">
                  改派
                </div>
                <!--   @click="handleConfirmComplete"   -->
                <div  v-if="role === 2" @click="handleConfirm">
                  确认完成
                </div>
              </div>
            </el-form-item>
          </div>
        </el-form>
      </div>
    </div>
    <el-dialog :visible.sync="showVideo"
               :close-on-click-modal="false"
               :modal-append-to-body="false"
               :append-to-body="false"
               @close="close()">
      <div v-if="formList.videoUrl">
        <video ref="videoShow" style="width:100%;height:100%" controls>
          <source :src="formList.videoUrl" type="video/mp4">。
        </video>
      </div>
      <div class="nodata" style="text-align: center" v-else>暂无视频</div>
    </el-dialog>
    <el-dialog :visible.sync="showVideoInfo"
               :close-on-click-modal="false"
               :modal-append-to-body="false"
               :append-to-body="false"
               @close="close2()">
        <div v-if="videoUrlInfo">
          <video ref="videoShow" style="width:100%;height:100%" controls>
            <source :src="videoUrlInfo" type="video/mp4">。
          </video>
        </div>
        <div class="nodata" style="text-align: center" v-else>暂无视频</div>
    </el-dialog>
  </div>
</template>
<script>
import API from '@/api'
import { get_time_diff } from "@/utils/time";
export default {
  name: "faultDetail",
  data() {
    return {
      flag: null,
      hour: 0,
      showVideo: false,
      showVideoInfo: false,
      minute: 0,
      second: 0,
      formList: {},
      infoList: [],
      videoUrlInfo: '',
      time: '00:00:00',
      role: '',
      userId: ''
    }
  },
  created() {
      setTimeout(() => {
        this.role = this.$store.state.userInfo.roleType
        this.userId = this.$store.state.userInfo.userId
        console.log(this.role)
      }, 100);
    this.updateDevice()
  },
  mounted () {
    this.initTimer();
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    updateDevice() {
      API.searchList({ deviceRepairId: this.$route.query.deviceRepairId }).then(res => {
        console.log(res)
        this.formList = res.message.data
      })
      API.detailList({ deviceRepairId: this.$route.query.deviceRepairId }).then(res => {
        this.infoList = res.message.data
      })
    },
    initTimer () {
      // 获取当前维修单的保存的时间点（即当前维修单的开始时间点）
      this.flag = setInterval(() => {
        const oldTime = window.localStorage.getItem(this.$route.query.deviceRepairId || "")
        // 用当前最新的时间减去获取的时间点
        // 根据相减的结果格式化成time
        const diff = get_time_diff(oldTime)

        this.time = this.complZero(diff.hours1) +
            ":" +
            this.complZero(diff.minutes1) +
            ":" +
            this.complZero(diff.seconds1);
        // console.log(this.time)
      })

    },
    playVideo () {
      this.showVideo = true;
    },
    close() {
      this.showVideo = false;
    },
    close2() {
      this.showVideoInfo = false
    },
    playVideo2(item) {
      this.videoUrlInfo = item.videoUrl
      this.showVideoInfo = true;
    },
    getList () {
      // getInfo().then(res => {
      //   this.role = res.data.roleType
      // })
      API.searchList({ deviceRepairId: this.$route.query.deviceRepairId }).then(res => {
        // console.log(res)
        this.formList = res.message.data
      })
      API.detailList({ deviceRepairId: this.$route.query.deviceRepairId }).then(res => {
        this.infoList = res.message.data
      })
    },
    handleStart () {
      console.log(this.time)
      API.startWork({ repairId: this.$route.query.deviceRepairId }).then(res => {
        console.log(this.time)
        if (res.message.success === true) {
          this.getList()
          // 改订单状态
          // message('success', "操作成功")
          // 为当前维修单保存当前时间点
          window.localStorage.setItem(this.$route.query.deviceRepairId || "", JSON.stringify(new Date().getTime()))
          this.initTimer()
        }
      })
    },
    // 工作结束
    handleEnd () {
      this.$router.push({ path: 'maintenanceReport', query: { repairId: this.$route.query.deviceRepairId, time: this.time,orderType:this.formList.orderType } })
    },
    // 改派
    handleSend() {
      this.$router.push({path: 'personnelSelection',query: {brandId: this.formList.brandId, userId: this.$route.query.deviceRepairId}})
    },
    //确认完成
    handleConfirm() {
      API.finishWork({repairId:this.$route.query.deviceRepairId}).then(res =>{
        console.log(res)
        // message('success', "订单已完成")
        this.getList()
      })
    },
    complZero (n) {
      return n < 10 ? "0" + n : "" + n;
    },
  }
}
</script>
<style>
.widthTable {
  width: 1550px;
}
.title {
  font-size: 18px;
  color: #3D3D3D;
  font-weight: 500;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
.title div:last-child{
  font-size: 16px;
  font-weight: 400;
}
.el-table .hidden-row {
  display: none;
}
.bofang {
  cursor: pointer;
}
</style>
